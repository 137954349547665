var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Grid", {
        key: _vm.key,
        attrs: { service: _vm.service, route_name: "practical_test_teachers" },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function (actions) {
              return [
                _c("feather-icon", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip.auto",
                      value: {
                        content: "Abrir avaliação",
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      },
                      expression:
                        "{\n            content: 'Abrir avaliação',\n            delay: {\n              show: 500,\n              hide: 500\n            }\n          }",
                      modifiers: { auto: true },
                    },
                  ],
                  attrs: {
                    icon: "EyeIcon",
                    svgClasses: "h-5 w-5 mb-1 mr-3 stroke-current text-primary",
                  },
                  on: { click: () => _vm.openPraticalTest(actions.row) },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }